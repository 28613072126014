<section class="home slider" id="home"> 
    <div class="home-content">
        <div class="container">
  <h1>LUMA | <span class="element" data-text1="Terrace " data-text2="Club " data-text3="Lounge " data-text4="Bar " data-loop="true" data-backdelay="2000"></span></h1> 
            <!--<div class="social">
                <a target="_blank" href="https://www.facebook.com/LumaVRM/"><i class="fa fa-facebook" aria-hidden="true"></i>  </a>                        
                <a target="_blank" href="https://www.instagram.com/luma_vieiradominho"><i class="fa fa-instagram" aria-hidden="true"></i>  </a>                                                
                <a target="_blank" href="https://www.youtube.com/channel/UCCPpAkqSxJdP37S8K26rPxA"><i class="fa fa-youtube" aria-hidden="true"></i>  </a>
    <a target="_blank" href="https://www.tripadvisor.pt/Profile/luma_vieiradominho"><i class="fa fa-tripadvisor" aria-hidden="true"></i>  </a>
            </div>-->
            <!--<a class="home-down bounce" href="#about"><i class="fa fa-angle-down"></i></a>-->
        </div>
    </div>
    <!-- video -->
     <div class="home-video">
        <div id="block" style="width: 100%; height: 100%;" data-vide-options="muted: false, position: 0% 50%"></div>
     </div>
<svg class="diagonal home-left" width="21%" height="170" viewBox="0 0 100 102" preserveAspectRatio="none">
    <path d="M0 100 L100 100 L0 10 Z"></path>
</svg>
<svg class="diagonal home-right" width="80%" height="170" viewBox="0 0 100 102" preserveAspectRatio="none">
    <path d="M0 100 L100 100 L100 10 Z"></path>
</svg>
</section>