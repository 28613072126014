import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartaComponent } from './lounge/carta/carta.component';
import { RootComponent } from './root/root.component';


const routes: Routes = [
    {path: 'lounge/carta', component: CartaComponent },
    {path: '', component: RootComponent },
    {path: '**', component: RootComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),        
    ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
