import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare function toastr(input: string): void;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

    contacto = { nome: "", email: "", mensagem: "" };

    constructor(private http: HttpClient) { }

    ngOnInit() {
    }

    enviarEmail = function () {
        console.dir(this.email);
        if (this.contacto.nome == undefined || this.contacto.nome == '')
            return;
        if (this.contacto.mensagem == undefined || this.contacto.mensagem == '')
            return;

        var api = this.http.post("https://guestlist.clubluma.pt/api/public/sendEmail", this.contacto);
        api.subscribe(
            (response) => {
                this.contacto = { nome: "", email: "", mensagem: "" };
                toastr('Mensagem enviada, Obrigado pelo contacto');
            });
    }
}