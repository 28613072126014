<footer>
    <div class="container">
        <div class="social">
            <a href="https://www.facebook.com/LumaVRM/">Facebook </a>
            <a href="https://www.instagram.com/luma_vieiradominho">Instagram </a>
            <a href="https://www.youtube.com/channel/UCCPpAkqSxJdP37S8K26rPxA">Youtube </a>
            <a href="https://www.tripadvisor.pt/Profile/luma_vieiradominho">TripAdvisor </a>
        </div>
        <div class="social">
            <p>by <a href="https://www.linkedin.com/in/afvcarneiro"><u><i>André Carneiro</i></u></a> Copyright © 2020 {{ ('base.rights') | translate }}
                <select style="margin-left: 15px;" #langselect (change)="changeLanguage(langselect)" [(ngModel)]="currentLang">
                    <option *ngFor="let lang of translate.getLangs()" [value]="lang"> {{lang}} </option>
                </select>
            </p>
        </div>
    </div>
</footer> 

<div id="snackbar" class=""></div>