<section class="lounge" id="lounge" >
    <div class="container-fluid  type-1">
        <div class="container">
            <div class="row">
                <div class="blog-single col-md-12">
                    <div class="blog-image" style="padding-top: 140px; ">
                        <img src="/assets/images/lounge/banner.jpg" alt="">
                    </div>  
                    <h1 class="section-title" style="text-align: left;">Lounge - Terrace Bar</h1>
                                        
                    <div class="blog-content">
                            
                        <p>O nosso espaço Lounge é constituído por duas áreas distintas: uma para fumadores e outra para não fumadores, com o maior conforto e requinte. </p>
                        <br/>  
                        <p>Nas épocas de sol poderá desfrutar de uma esplanada com vista para a natureza com uma nascente. Dispomos de um serviço variado em snacks, bar e cafetaria com qualidade.  </p>
                        <br/>  
                        <p>Apostamos em ambientes diferentes com DJ ao vivo, musica ao vivo, entre outros.</p>
                        <br/>  
                        <p>Venha visitar-nos que não se irá arrepender!</p>
                        <br/>
                        <!-- Lightbox images -->   
                        <div class="post-lightbox row">
                            <!-- image 1 -->
                            <a href="/assets/images/lounge/1.jpg" class="col-md-4 col-sm-4 col-xs-6 lightbox-image link">
                                <img src="/assets/images/lounge/1.jpg" alt="">
                            </a>
                            <!-- image 1 -->
                            <a href="/assets/images/lounge/2.jpg" class="col-md-4 col-sm-4 col-xs-6 lightbox-image link">
                                <img src="/assets/images/lounge/2.jpg" alt="">
                            </a>
                            <!-- image 1 -->
                            <a href="/assets/images/lounge/3.jpg" class="col-md-4 col-sm-4 col-xs-6 lightbox-image link">
                                <img src="/assets/images/lounge/3.jpg" alt="">
                            </a>
                        </div>
                    
                    </div>               
                </div>
            </div>
        </div>
        <svg class="diagonal-white" width="100%" height="170" viewBox="0 0 100 102" preserveAspectRatio="none">
            <path d="M0 0 L70 100 L100 0 Z"></path>
        </svg>
    </div>
  </section>