
<!--MENU-->
<app-menu></app-menu>

<!--HOME-->
<app-home></app-home>

<!-- ABOUT -->
<app-about></app-about>

<!-- LOUNGE -->
<app-lounge></app-lounge>

<!-- CLUB -->
<app-club></app-club>

<!-- AGENDA -->
<app-agenda></app-agenda>

<!--PORTFOLIO-->
<app-portfolio></app-portfolio>

<!-- CONTACT -->
<app-contact></app-contact>

<!-- FOOTER -->
<app-footer></app-footer>