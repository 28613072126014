<section class="contact" id="contact" >
  <div class="container">      
      <div class="section-title dleft top_120">
          <h2 class="bottom_30">{{ "contacts.getInContact" | translate }}</h2>
      </div>
      <div class="col-md-3 wow fadeInUp" data-wow-delay="0.3s">
          <!-- Contact Info -->
          <ul class="contact-info row">
              <li>Av. Jão da Torre, Vieira do Minho</li>
              <li>4850-523 Vieira do Minho</li>
              <li>geral@clubluma.pt</li>                    
          </ul>
          <div class="social-icons top_60 row"> 
              <a class="fb" href="https://www.facebook.com/LumaVRM"><i class="fa fa-facebook" aria-hidden="true"></i></a> 
              <a class="ins" href="https://www.instagram.com/luma_vieiradominho"><i class="fa fa-instagram" aria-hidden="true"></i></a> 
              <a class="bh" href="https://www.tripadvisor.pt/Profile/luma_vieiradominho"><i class="fa fa-tripadvisor" aria-hidden="true"></i></a> 
              <a class="dr" href="https://www.youtube.com/channel/UCCPpAkqSxJdP37S8K26rPxA"><i class="fa fa-youtube" aria-hidden="true"></i></a> 
          </div>
      </div>
      <div class="col-md-7 col-md-offset-2 form top_30 wow fadeInUp" data-wow-delay="0.6s">
              <div class="page-title sub">
                  <h5>{{ "contacts.givUsMessage" | translate }}</h5>
              </div>
              <form class="contact-form top_60">
                  <div class="row">
                      <!--Name-->
                      <div class="col-md-6">
                          <input id="con_name" name="con_name" class="form-inp requie" type="text" placeholder="{{ ('base.name') | translate }}" [(ngModel)]="contacto.nome" >
                      </div>
                      <!--Email-->
                      <div class="col-md-6">
                          <input id="con_email" name="con_email" class="form-inp requie" type="text" placeholder="{{ ('base.email') | translate }}" [(ngModel)]="contacto.email">
                      </div>
                      <div class="col-md-12">
                          <!--Message-->
                          <textarea name="con_message" id="con_message" class="requie" placeholder="{{ ('contacts.givUsMessage') | translate }}" rows="8" [(ngModel)]="contacto.mensagem"></textarea>
                          <button id="con_submit" class="sitebtn top_30 pull-right" type="button" (click)="enviarEmail()">{{ "base.send" | translate }}</button>
                      </div>
                  </div>
              </form>
          </div>
  </div>
</section>