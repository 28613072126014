import { Component, OnInit } from '@angular/core';

declare function loadVideo(): void;
declare function pageLoad(): void;
declare function pageReady(): void;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    loadVideo();
    pageReady();
    pageLoad();
  }

}
