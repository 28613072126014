<section class="club dgray-bg" id="club" >
    <div class="container-fluid  type-1">
        <div class="container" style="padding-bottom: 150px;">
            <div class="row">
                <div class="blog-single col-md-12">
                    <div class="blog-image" style="padding-top: 160px; ">
                        <img src="/assets/images/club/banner.jpg" alt="">
                    </div>  
                    <h1 class="section-title" style="text-align: left;">Club</h1>
                                        
                    <div class="blog-content">
                            
                        <p>O LUMA CLUB foi inaugurado no dia 26 de Maio de 2018.  </p>
                        <br/>  
                        <p>O espaço divide-se em três diferentes áreas: pista, área vip e camarotes, equipado com a melhor e mais recente tecnologia no que diz respeito ao som, luz e vídeo.  </p>

                        <blockquote>
                            Aqui já passaram inúmeros artistas de renome, nacionais e internacionais como: DAMA, Blaya, Dillaz, Piruka, Jimmy P, Putzgrilla, Julinho KSD, Supa Squad, ProfJam, Lon3r Jony, Plutónio, Wet Bed Gang, David Carreira, Quim Barreiros, JoJo Todinho, Carolina, Boy Teddy, Master Jake, TropKillaz, entre outros. 
                        </blockquote>

                        <p>Apostamos em um cartaz diversificado, contanto com os maiores e melhores artistas nacionais nos diversos estilos musicais alternando com as demais festas temáticas.Hoje, é uma casa de referência no roteiro dos espaços nocturnos em Portugal.</p>
                        <br/>
                        
                        <div class="post-lightbox isotope_items row">
                            <a href="/assets/images/club/1.jpg" class="col-md-4 col-sm-4 col-xs-6 lightbox-image link">
                                <img src="/assets/images/club/1.jpg" alt="">
                            </a>
                            <a href="/assets/images/club/2.jpg" class="col-md-4 col-sm-4 col-xs-6 lightbox-image link">
                                <img src="/assets/images/club/2.jpg" alt="">
                            </a>
                            <a href="/assets/images/club/3.jpg" class="col-md-4 col-sm-4 col-xs-6 lightbox-image link">
                                <img src="/assets/images/club/3.jpg" alt="">
                            </a>
                            <a href="/assets/images/club/4.jpg" class="col-md-4 col-sm-4 col-xs-6 lightbox-image link">
                                <img src="/assets/images/club/4.jpg" alt="">
                            </a>
                            <a href="/assets/images/club/5.jpg" class="col-md-4 col-sm-4 col-xs-6 lightbox-image link">
                                <img src="/assets/images/club/5.jpg" alt="">
                            </a>
                            <a href="https://www.youtube.com/watch?v=raewDjTiHfI" class="popup-youtube single_item link development col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                                <i class="fa fa-play" aria-hidden="true"></i>   
                                <figure>
                                    <img src="/assets/images/club/6.jpg" alt=""> 
                                    <figcaption>
                                        <div class="outter">
                                            <div class="inner">                       
                                            </div>
                                        </div>
                                    </figcaption>
                                </figure>
                            </a>
                        </div>
                    
                    </div>               
                </div>
            </div>
        </div>
        <svg class="diagonal-gray" width="100%" height="170" viewBox="0 0 100 102" preserveAspectRatio="none">
            <path d="M0 0 L30 100 L100 0 Z"></path>
        </svg>
    </div>
  </section>