import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})

export class PortfolioComponent implements OnInit {

    imagesPortfolio = [
        {class: 'artist',   image: 'blaya', name: 'Blaya', label: '' }, 
        {class: 'club',     image: 'club1', name: '', label: 'Club' }, 
        {class: 'lounge',   image: 'lounge1', name: '', label: 'Lounge' }, 
        {class: 'artist',   image: 'quim', name: 'Quim Barreiros', label: '' }, 
        {class: 'club',     image: 'club2', name: '', label: 'Club' }, 
        {class: 'lounge',   image: 'lounge2', name: '', label: 'Lounge' }, 
        {class: 'artist',   image: 'mc_rita', name: 'MC Rita', label: '' }, 
        {class: 'club',     image: 'club3', name: '', label: 'Club' }, 
        {class: 'lounge',   image: 'lounge3', name: '', label: 'Lounge' }, 
        {class: 'artist',   image: 'toy', name: 'Toy', label: '' }, 
        {class: 'club',     image: 'club4', name: '', label: 'Club' }, 
        {class: 'lounge',   image: 'lounge4', name: '', label: 'Lounge' }, 
        {class: 'artist',   image: 'jimmyP', name: 'Jimmy P', label: '' }, 
        {class: 'club',     image: 'club5', name: '', label: 'Club' }, 
        {class: 'lounge',   image: 'lounge5', name: '', label: 'Lounge' }, 
        {class: 'artist',   image: 'magga_braco', name: 'Magga Braco', label: '' }, 
        {class: 'club',     image: 'club6', name: '', label: 'Club' }, 
        {class: 'lounge',   image: 'lounge6', name: '', label: 'Lounge' }, 
        {class: 'artist',   image: 'piruka', name: 'Piruka', label: '' }, 
        {class: 'club',     image: 'club7', name: '', label: 'Club' }, 
        {class: 'lounge',   image: 'lounge7', name: '', label: 'Lounge' }, 
        {class: 'artist',   image: 'wbg', name: 'Wet Bed Gang', label: '' }, 
        {class: 'club',     image: 'club8', name: '', label: 'Club' }, 
        {class: 'lounge',   image: 'lounge8', name: '', label: 'Lounge' }, 
        {class: 'club',     image: 'club9', name: '', label: 'Club' },         
        {class: 'lounge',   image: 'lounge9', name: '', label: 'Lounge' }, 
        
        // {class: 'video', image: 'video1', link: '', name: '', label: '' }, 
        // {class: 'video', image: 'video2', link: '', name: '', label: '' }, 
        // {class: 'video', image: 'video3', link: '', name: '', label: '' }        
    ]

  constructor(private translate: TranslateService) { 
  }

  ngOnInit(): void {
  }
}
