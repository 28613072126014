<!--MENU-->
<app-menu></app-menu>

<div class="container blog-single lounge-carta" style="padding-top: 115px;">

  <h1 class="title">Lounge - Terrace Bar : CARTA</h1>


  <div class="row">
    <div class="col-xs-12">
      <input type="text" placeholder="o que queres tomar?" class="form-inp requie" (keyup)="findItems($event)" />
    </div>
  </div>

  <div class="accordion-item " *ngFor="let categoria of getCategorias()">
    <div class="accordion categoria">{{categoria}}</div>

    <div class="accordion-panel carta-items">

      <div class="categoria-desc align-right" [hidden]="categoria != 'GELADOS SPIRITO'">
        <span><i> * clica para ver mais</i></span>
      </div>

      <div (click)="openItem(item);" class="row item" *ngFor="let item of getItemsByCategoria(categoria)">
        <div class="col-xs-2" [hidden]="!item.image">
          <div class="border pad-r-5">
            <a class="lightbox-image link" [ngClass]="{'link': item.image}"
              href="assets/images/lounge/carta/{{item.image}}">
              <img class="img-carta" src="assets/images/lounge/carta/{{item.image}}">
            </a>
          </div>
        </div>
        <div class="pad-l-5" [ngClass]="{'col-xs-6': item.image, 'col-xs-8': !item.image}">
          <span class="nome">{{item.nome}}</span>
          <span [hidden]="!item.shortDetail" class="obs">{{item.shortDetail}}</span>
        </div>
        <div class="col-xs-4 master">
          <div class="detail"><span class="preco1" *ngIf="item.preco1"> {{item.preco1 }} </span></div>
          <div class="detail"><span class="preco2" *ngIf="item.preco2"> {{item.preco2 }} </span></div>
          <div class="detail"><span class="preco3" *ngIf="item.preco3"> {{item.preco3 }} </span></div>
          <div class="detail"><span class="preco4" *ngIf="item.preco4"> {{item.preco4 }} </span></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- page footer -->
<div class="owl-carousel work-areas top_120 bottom_120 wow fadeInUp" data-pagination="false" data-autoplay="3000"
  data-items-desktop="3" data-items-desktop-small="3" data-items-tablet="2" data-items-tablet-small="1"
  data-wow-delay="0.4s">
  <!-- an area 283x207-->
  <div class="area col-md-12 item">
    <div class="blog-image">
      <img src="assets/images/about_us/DSC_1080.JPG">
    </div>
  </div>
  <!-- an area -->
  <div class="area col-md-12 item">
    <div class="blog-image">
      <img src="assets/images/about_us/DSC_1087.JPG">
    </div>
  </div>
  <!-- an area -->
  <div class="area col-md-12 item">
    <div class="blog-image">
      <img src="assets/images/about_us/DSC_1131.JPG">
    </div>
  </div>
  <!-- an area -->
  <div class="area col-md-12 item">
    <div class="blog-image">
      <img src="assets/images/about_us/DSC_1092.JPG">
    </div>
  </div>
  <!-- an area -->
  <div class="area col-md-12 item">
    <div class="blog-image">
      <img src="assets/images/about_us/DSC_1107.JPG">
    </div>
  </div>
  <!-- an area -->
  <div class="area col-md-12 item">
    <div class="blog-image">
      <img src="assets/images/about_us/DSC_1116.JPG">
    </div>
  </div>
  <!-- an area -->
  <div class="area col-md-12 item">
    <div class="blog-image">
      <img src="assets/images/about_us/DSC_1123.JPG">
    </div>
  </div>
  <!-- an area -->
  <div class="area col-md-12 item">
    <div class="blog-image">
      <img src="assets/images/about_us/DSC_1095.JPG">
    </div>
  </div>
</div>


<!-- FOOTER -->
<app-footer></app-footer>



<div id="myModal" class="modal modal-medium lounge-carta">
  <!-- Modal content -->
  <div class="modal-content">
    <span class="close">&times;</span>
    <h1>{{currentItem.nome}}</h1>
    <br>
    <div class="row item">
      <div class="col-xs-6 lightbox-image" [hidden]="!currentItem.image">
        <a class="lightbox-image link" [ngClass]="{'link': currentItem.image}"
          href="assets/images/lounge/carta/{{currentItem.image}}">
          <img class="img-carta" src="assets/images/lounge/carta/{{currentItem.image}}">
        </a>
      </div>
      <div class="col-xs-6 master">
        <div class="detail"><span *ngIf="currentItem.preco1"> {{currentItem.preco1 }} |&nbsp; 1
            bola&nbsp;&nbsp;</span></div>
        <div class="detail"><span *ngIf="currentItem.preco2"> {{currentItem.preco2 }} | 2 bolas</span></div>
        <div class="detail"><span *ngIf="currentItem.preco3"> {{currentItem.preco3 }} | 3 bolas</span></div>
      </div>
    </div>
    <div class="row item">
      <div class="col-xs-12">
        <p> <b>Toppings</b> <i> 0,30 €</i></p>
      </div>
      <!-- <div class="col-xs-6">
            <h1>Coberturas</h1>
        </div> -->
    </div>
    <div class="row item blog-single">
      <div class="col-xs-12">
        <blockquote>Caramelo</blockquote>
        <blockquote>Morango</blockquote>
        <blockquote>Chocolate</blockquote>
        <blockquote>Chocolate Branco</blockquote>
        <blockquote>Frutos Silvestres</blockquote>
        <blockquote>Maracujá</blockquote>
      </div>
      <!-- <div class="col-xs-6">
            <p>Caramelo</p>
        </div> -->
    </div>

  </div>
</div>
