import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lounge',
  templateUrl: './lounge.component.html',
  styleUrls: ['./lounge.component.css']
})
export class LoungeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {    
  }
}