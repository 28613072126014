import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';

import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './footer/footer.component';
import { AgendaComponent } from './agenda/agenda.component';
import { ContactComponent } from './contact/contact.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { LoungeComponent } from './lounge/lounge.component';
import { ClubComponent } from './club/club.component';
import { CartaComponent } from './lounge/carta/carta.component';
import { RootComponent } from './root/root.component';


@NgModule({
   declarations: [
      AppComponent,
      MenuComponent,
      FooterComponent,
      HomeComponent,
      AboutComponent,      
      AgendaComponent,
      ContactComponent,
      PortfolioComponent,
      LoungeComponent,
      ClubComponent,
      CartaComponent,
      RootComponent,
   ],
   imports: [
      BrowserModule,      
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),   
      FormsModule,
      AppRoutingModule
   ],
   providers: [ CookieService ],
   bootstrap: [ AppComponent ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}