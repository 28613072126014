<section class="about dgray-bg" id="about">
    <div class="about type-1 padbot_120">
        <div class="container">
            <!-- about image -->
            <div class="col-md-4 col-sm-12 about-image top_30 wow fadeInUp"  data-wow-delay="0.4s">
                <div class="row">
                    <img src="assets/images/about_us/DSC_1102.JPG" alt="">
                </div>
            </div>
            <!-- about text -->
            <div class="col-md-7 col-md-offset-1 col-sm-12 about-text wow fadeInUp"  data-wow-delay="0.6s">
                <div class="section-title dleft bottom_30">
                  <br>
                    <h2>{{ 'base.aboutUs' | translate }}</h2>
                </div>
                <p> {{ 'about.text1' | translate }} <b>DAMA</b>.
                    {{ 'about.text2' | translate }}: <b> Lounge Bar {{ 'about.text3' | translate }}</b>, {{ 'about.text4' | translate }}
                <br><br>
                    {{ 'about.text5' | translate }}:
                    <b>DAMA, Blaya, MC Rita</b> ({{ 'about.text6' | translate }}), <b>Dillaz, Jimmy P, Wet Bed
                    Gang, Carolina (Mafiosa), Magga Braco, TOY, Quim Barreiros, Kataleya</b>,
                    {{ 'about.text7' | translate }}. {{ 'about.text8' | translate }}
                </p>
            </div>
            
            <!-- work areas -->
            <div class="owl-carousel work-areas top_120 bottom_120 wow fadeInUp" data-pagination="false" data-autoplay="3000" data-items-desktop="3" data-items-desktop-small="3" data-items-tablet="2" data-items-tablet-small="1"  data-wow-delay="0.4s">
                <!-- an area 283x207-->
                <div class="area col-md-12 item">                    
                    <div class="blog-image">
                        <img src="assets/images/about_us/DSC_1080.JPG">
                    </div>
                </div>
                <!-- an area -->
                <div class="area col-md-12 item">                    
                    <div class="blog-image">
                        <img src="assets/images/about_us/DSC_1087.JPG">
                    </div>
                </div>
                <!-- an area -->
                <div class="area col-md-12 item">                    
                    <div class="blog-image">
                        <img src="assets/images/about_us/DSC_1131.JPG">
                    </div>
                </div>
                <!-- an area -->
                <div class="area col-md-12 item">                    
                    <div class="blog-image">
                        <img src="assets/images/about_us/DSC_1092.JPG">
                    </div>
                </div>
                <!-- an area -->
                <div class="area col-md-12 item">                    
                    <div class="blog-image">
                        <img src="assets/images/about_us/DSC_1107.JPG">
                    </div>
                </div>
                <!-- an area -->
                <div class="area col-md-12 item">                    
                    <div class="blog-image">
                        <img src="assets/images/about_us/DSC_1116.JPG">
                    </div>
                </div>
                <!-- an area -->
                <div class="area col-md-12 item">                    
                    <div class="blog-image">
                        <img src="assets/images/about_us/DSC_1123.JPG">
                    </div>
                </div>
                <!-- an area -->
                <div class="area col-md-12 item">                    
                    <div class="blog-image">
                        <img src="assets/images/about_us/DSC_1095.JPG">
                    </div>
                </div>
            </div>

        </div>
        <svg class="diagonal-gray" width="100%" height="170" viewBox="0 0 100 102" preserveAspectRatio="none">
            <path d="M0 0 L30 100 L100 0 Z"></path>
        </svg>
    </div>
</section> 