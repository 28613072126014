import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    currentLang: string;

    constructor(private cookieService: CookieService, public translate: TranslateService) { }

    ngOnInit(): void {
        this.setLanguage();
    }

    setLanguage() {
        this.translate.addLangs(['pt', 'en', 'es']);
        this.translate.setDefaultLang('pt');
        const selectedLang = this.cookieService.get('lang');
        this.currentLang = selectedLang ? selectedLang : this.translate.getBrowserLang();
        this.translate.use(this.currentLang.match(/pt|en|es/) ? this.currentLang : 'pt');
    }

    changeLanguage(langselect: any) {
        this.translate.use(langselect.value)
        this.cookieService.set('lang', langselect.value, new Date(new Date().getFullYear()+1,11,31), null, null, false, "Strict");
    }
}
