import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare function magnificPopup(): void;

@Component({
	selector: 'app-agenda',
	templateUrl: './agenda.component.html',
	styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {

	agenda: any;

	constructor(private http: HttpClient) {
		this.getAgenda();
	}

	ngOnInit() {
	}

	getAgenda() {
		let api = this.http.get("https://guestlist.clubluma.pt/api/public/getAgenda");
		api.subscribe((data) => {
			this.agenda = data;
			setTimeout(function () {
				magnificPopup();
			}, 1);
		}
		);
	}
}
