<nav>
    <div class="row">
        <div class="container">
            <div class="logo" >
                <img src="assets/images/logotipo.jpg" alt="">
            </div>
            <div class="responsive"><i data-icon="m" class="icon"></i></div>
            <ul class="nav-menu">
                <li><a href="#home" class="smoothScroll">{{ 'menu.home' | translate }}</a></li>
                <li><a href="#about" class="smoothScroll">{{ 'menu.about' | translate }}</a></li>
                <li><a href="#lounge" class="smoothScroll">Lounge</a></li>
                <li><a href="#club" class="smoothScroll">Club</a></li>
                <li><a href="#agenda" class="smoothScroll">{{ 'menu.agenda' | translate }}</a></li>
                <li><a href="#portfolio" class="smoothScroll">{{ 'menu.portfolio' | translate }}</a></li>                
                <li><a href="#contact" class="smoothScroll">{{ 'menu.contacts' | translate }}</a></li>               
            </ul>
        </div>
    </div>
</nav>