<section class="agenda" id="agenda" >
  <div class="container-fluid ">
      <div class="container">
          <div class="section-title dleft top_120 bottom_45">
              <h2>{{ ('menu.agenda') | translate }}</h2>
          </div>				
          <!-- Blogs -->
          <div class="row">				
              <a *ngFor="let item of agenda" href="{{item.imagem}}"  class="single_item link col-lg-4 col-md-4 col-sm-6 col-xs-12 blog-content wow fadeInUp" data-wow-delay="0.4s">
                  <div class="blog-image">
                      <img src="{{item.imagem}}">
                  </div>
                  <h2 class="blog-title">{{item.titulo}} </h2>
                  <span class="blog-info"><span>{{item.data | date : "EEE"}}.</span> - {{item.data | date : "dd MMM"}} </span>
              </a>
          </div>
      </div>
      <svg class="diagonal-white" width="100%" height="170" viewBox="0 0 100 102" preserveAspectRatio="none">
          <path d="M0 0 L70 100 L100 0 Z"></path>
      </svg>
  </div>
</section>