import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare function init_accordion(): void;
declare function init_menu(): void;
declare function pageLoad(): void;
declare function pageReady(): void;
declare function init_magnificPopup(): void;
declare function modal(): void;

@Component({
  selector: 'app-carta',
  templateUrl: './carta.component.html',
  styleUrls: ['./carta.component.css']
})
export class CartaComponent implements OnInit {

  private items: any;
  private allItems: any;
  currentItem = {
    image: '',
    nome: '',
    preco1: '',
    preco2: '',
    preco3: '',
    preco4: '',
    categoria: '',
    ordemCategoria: 0,
    shortDetail: ''
  };

  constructor(private http: HttpClient) {
    this.getItems();
  }

  ngOnInit(): void {
    pageLoad();
    pageReady();
    init_menu();

  }

  getItems() {
    let api = this.http.get("https://guestlist.clubluma.pt/api/public/getCartalounge");
    api.subscribe((data) => {
      this.items = data;
      this.allItems = this.items;
      this.accordion();
    });
  }

  getItemsByCategoria(categoria: string) {
    if (!categoria)
      return;

    return this.items.filter(e => e.categoria.toLowerCase() == categoria.toLowerCase());
  }

  getCategorias() {
    if (!this.items)
      return;

    return this.items.map(item => item.categoria).filter((value, index, self) => self.indexOf(value) === index)
  }

  findItems(event: any) {
    this.items = this.allItems.filter(e => e.nome.toLowerCase().includes(event.target.value.toLowerCase()));
    this.accordion();
  }

  openItem(item) {

    this.currentItem = item;

    if (this.currentItem.categoria === "GELADOS CARTE D'OR")
      modal();
  }

  private accordion() {
    setTimeout(function () {
      init_accordion();
      init_magnificPopup();
    }, 1);
  }
}
