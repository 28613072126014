<section class="portfolio dgray-bg" id="portfolio">
    <div class="container-fluid type-1" style="padding-bottom: 337px;">
        <div class="container">
            <div class="section-title dleft top_120 bottom_90">
                <h2>{{ 'menu.portfolio' | translate }}</h2>
                <div class="portfolio_filter">
                    <ul>
                        <li class="select-cat" data-filter="*">{{ 'portfolio.all' | translate }}</li>
                        <li data-filter=".club">{{ 'portfolio.club' | translate }}</li>
                        <li data-filter=".lounge">{{ 'portfolio.lounge' | translate }}</li>                    
                        <li data-filter=".artist">{{ 'portfolio.artist' | translate }}</li>
                        <!-- <li data-filter=".videos">{{ 'portfolio.video' | translate }}</li> -->
                    </ul>
                </div>
            </div>

            <!--Portfolio Items-->  
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="isotope_items row">
                        <a *ngFor="let item of imagesPortfolio" href="assets/images/portfolio/{{item.image}}.jpg" class="single_item link col-md-4 col-sm-6 col-xs-6 wow fadeInUp {{item.class}}" data-wow-delay="0.6s">
                            <figure>
                                <img src="assets/images/portfolio/{{item.image}}.jpg" alt=""> 
                                <figcaption>
                                    <div class="outter">
                                        <div class="inner">
                                            <h3 class="title">{{item.name}}</h3>
                                            <span>{{item.label}}</span>
                                        </div>
                                    </div>
                                </figcaption>
                            </figure>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <svg class="diagonal-gray" width="100%" height="170" viewBox="0 0 100 102" preserveAspectRatio="none">
            <path d="M0 0 L30 100 L100 0 Z"></path>
        </svg>
    </div>
</section>